import React from 'react';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';

interface ComptWarningBannerProps {
  messageComponent: React.ReactNode;
}

export const ComptWarningBanner = (props: ComptWarningBannerProps) => (
  <div className="flex justify-between p-3 mb-3 border border-yellow-300 rounded-md">
    <div className="flex items-center">
      <div
        className="flex justify-center items-center w-10 h-10 p-2.5 mr-3 rounded-lg border
        border-yellow-300 bg-yellow-050"
      >
        <ComptSvgIcon iconName="alert-triangle-warning-icon" ariaLabel="alert-triangle-warning" />
      </div>
      <p className="body1 text-color-body2">{props.messageComponent}</p>
    </div>
  </div>
);
